mat-dialog-container {
  padding: 0 !important;
}

mat-dialog-content {
  margin: 0 !important;
  padding: 0 !important;
}
.mat-button, .mat-icon-button, .mat-stroked-button{
    color: #008837 !important;
}
.c-controll-date {
  .mat-input-element{
    text-align: center !important;
    font-family: Open Sans !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 16px !important;
    color: #495057 !important;
    margin-bottom: rem(8) !important;
  }

  .mat-form-field-infix{
    padding: 0px 0px 0px 0px !important;
    border-top: 0px solid !important;

  }
  .mat-form-field-appearance-fill .mat-form-field-flex{
    padding: 0px 0px 0px 0px !important;
  }
  .mat-form-field-label-wrapper{
    display: none !important;
    // margin-left: rem(16);
  }
  .mat-form-field-underline{
    display:none !important;
  }
  .mat-form-field-wrapper{
    margin-bottom: rem(2);
  }
  mat-form-field:nth-child(1) > .mat-form-field-wrapper:nth-child(1){
    background: white;
    padding-bottom: 0 !important;
    height: rem(40);
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }
  mat-form-field:nth-child(2) > .mat-form-field-wrapper:nth-child(1){
    background: white;
    padding-bottom: 0 !important;
    height: rem(40);
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-left: 1px solid #CED4DA !important;
  }
}
.mat-menu-panel{
  // margin-right: rem(44) !important;
  width: rem(272) !important;
  background: #FFFFFF !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04) !important;
  border-radius: rem(7) !important;
}

.mat-menu-item{
  height: rem(35) !important;
}

.mat-radio-button ~ .mat-radio-button {
}


.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color: #008837 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle{
  color:#008837 !important;
  background-color:#008837  !important;
}
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color:#008837 !important;
}


.mat-checkbox-ripple .mat-ripple-element {
  background-color:  #008837 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #008837 !important;
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #008837 !important;
}



::ng-deep .mat-form-field-flex > .mat-form-field-infix { padding: 0.4em 0px !important;}
::ng-deep .mat-form-field-label-wrapper { top: -1.5em; }

::ng-deep .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
    transform: translateY(-1.1em) scale(.75);
    width: 133.33333%;
}
