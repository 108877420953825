@charset "utf-8";
@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import "~leaflet.polylinemeasure/Leaflet.PolylineMeasure.css";
// ==========================================================================
// Abstract
// ==========================================================================

@import "abstract/unit";
@import "abstract/variables";
@import "abstract/breakpoint";
@import "abstract/z-index";

:root {
  @each $name,
  $color in $somaicovid-colors {
    --color-#{$name}: #{$color};
  }

  @each $name,
  $color in $dpw-colors {
    --color-#{$name}: #{$color};
  }

  @each $name,
  $color in $social-media-colors {
    --color-#{$name}: #{$color};
  }
}

// ==========================================================================
// Vendor
// ==========================================================================

@import "vendor/normalize";

// ==========================================================================
// Base
// ==========================================================================

@import "base/elements";
@import "base/a11y";

// ==========================================================================
// Layout
// ==========================================================================

@import "layout/l-header";
@import "layout/l-main";
@import "layout/l-menu";
@import "layout/l-aside";
@import "layout/l-content";
@import "layout/l-content_login";
@import "layout/l-footer";

// ==========================================================================
// Custom Material
// ==========================================================================
@import "custom/material.scss";

// ==========================================================================
// Custom ng-select
// ==========================================================================
@import "custom/ng-select.scss";

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Poppins, "", sans-serif;
}

@import '~ngx-sharebuttons/themes/material/material-dark-theme';
@import '~ngx-sharebuttons/themes/modern/modern-light-theme';
@import '~ngx-sharebuttons/themes/circles/circles-dark-theme';
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";
.cdk-overlay-container{
  z-index: 1003 !important;
}


a.polyline-measure-unicode-icon, a.polyline-measure-unicode-icon:hover {
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d8a37;
}

.highcharts-data-table{
  display: none !important;
}

.ng-dropdown-panel {
  width: auto !important;
}

app-header{
  background-color: white;
}

app-header-login{
  background-color: white;
}

app-header-user{
  background-color: white;
}

a[title="Cancel drawing"]{
  display: none;
}

// ==========================================================================
// Custom Leaflet
// ==========================================================================
@import "custom/leaflet.scss";
