@import "../variables";
@import "../main";

.sb-modern-light .sb-wrapper {
  $sb-border-radius: 3px;
  $separate-shadow: 1px 0 1px -1px rgba(0, 0, 0, 0.3);

  @each $button,
  $color in $sb-buttons {
    &.sb-#{$button} {
      color: $color;
    }
  }

  color: var(--button-color) !important;
  background-color: $sb-accent;
  transition: box-shadow ease-in 150ms, transform ease-in 150ms;
  border-radius: $sb-border-radius;

  box-shadow: 0 0.1em 0.5em rgba($sb-gray, 0.6);
  &:active {
    box-shadow: 0 0.4em 1em rgba($sb-gray, 0.9);
  }

  .sb-icon {
    min-width: 2em;
  }
  &.sb-show-text {
    padding: 0;
    .sb-icon {
      box-shadow: $separate-shadow;
    }
  }
}
