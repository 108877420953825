$layout-name: "l-content-login";

.#{$layout-name} {
  background: #F4F7EC url("/assets/img/login-background.png") no-repeat 100% 100%;
  background-size: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: content;
  color: var(--color-somaicovid-white);
  width: 100%;
  height: calc(100vh - 85px);

  @media (min-width: 75em) { // 1200px
    height: calc(100vh - 96px);
  }
}
