html {
  box-sizing: border-box;
}

// @see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// @see https://www.nicchan.me/blog/tips-for-focus-styles/#consider-adding-a-little-bit-of-breathing-room
*,
*::before,
*::after {
  box-sizing: inherit;
  outline-offset: 0.25rem;
}

// @see: https://css-tricks.com/revisiting-prefers-reduced-motion-the-reduced-motion-media-query/
@media (prefers-reduced-motion: reduce), (update: slow) {
  *,
  ::before,
  ::after {
    animation-duration: 0.001ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

// @see https://www.zachleat.com/web/fluid-images/
img,
iframe,
object,
embed,
video {
  max-width: 100%;
}

img[width],
iframe[width],
object[width],
embed[width],
video[width] {
  width: auto; /* Defer to max-width */
}

img[width][height],
iframe[width][height],
object[width][height],
embed[width][height],
video[width][height] {
  height: auto; /* Preserve aspect ratio */
}

/* Let SVG scale without boundaries */
img[src$=".svg"] {
  height: auto;
  max-width: none;
  width: 100%;
}

label {
  cursor: pointer;
}

sup,
sub {
  font-size: 50%;
}

// @see https://matthiasott.com/notes/focus-visible-is-here
:focus:not(:focus-visible) {
  outline: 0;
}

// ==========================================================================
// Project specifics
// ==========================================================================

body {
  font-family: Nunito, -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto, noto, arial, sans-serif;
  font-size: $global-font-size;
}

// Show focus styles on keyboard focus
// :focus-visible {
// }

// ::selection {
// }

// ::-moz-selection {
// }

// input::placeholder {}
// textarea::placeholder {}
// input::-webkit-input-placeholder {}
// textarea::-webkit-input-placeholder {}
// input::-moz-placeholder {}
// textarea::-moz-placeholder {}
// input:-ms-input-placeholder {}
// textarea:-ms-input-placeholder {}
