$layout-name: "l-main";

.#{$layout-name} {
  display: grid;
  grid-area: main;
  grid-template-areas:
    "header"
    "content";
  background-color: #fafafa;
  grid-template-rows: minmax(0, auto) 1fr;
  color: var(--color-somaicovid-white);
  height: 100vh;

  @include breakpoint(large-up) {
    grid-template-areas:
      "header header header"
      "content content content";
    grid-template-columns: 1fr;
    grid-template-rows: minmax(0, auto) calc(100vh - auto);
    padding: inherit;
  }
}
