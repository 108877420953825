$layout-name: "l-header";
$layout-name-main: "l-header-main";

.#{$layout-name} {
  background-color: var(--color-somaicovid-white);
  color: var(--color-somaicovid-gray-1);
  grid-area: header;
  padding-left: rem(20);
  padding-right: rem(20);
  //margin-top: rem(16);
  // height: rem(80);
  @include breakpoint(large-up) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    margin-top: rem(15);
    padding-left: rem(40);
    padding-right: rem(40);
    // height: rem(60);
  }
}
@media (min-width: 75em) {
  .l-header {
    margin-top: 0 !important;
  }
}

.#{$layout-name-main} {
  display: flex;
  width: 100%;

  &__logo {
    img {
      width: rem(120);
      height: rem(48);
    }
  }
  &__content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: rem(20);
    span.content-title {
      display: none;
    }
    .user-avatar .avatar-content {
      font-family: 'Open Sans' !important;
      font-size: 18px !important;
    }
    
  }
  @include breakpoint(large-up) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    &__logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: rem(160);
        height: rem(64);
      }
      
      div {
        width: rem(48);
        border: 1px solid var(--color-somaicovid-gray-7);
        transform: rotate(90deg);
        margin-left: rem(20);
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-left: rem(20);

      span.content-title {
        display: block;
        font-family: Roboto, "Helvetica Neue", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 16px;
        letter-spacing: 1px;
        color: #008837;
       
      }

      .c-header__icons{
        display: flex;
        align-items: center;
        justify-content:space-between;
        width: rem(200);
        div {
          height: rem(24);
          border: 1px solid var(--color-somaicovid-gray-7);
          margin-right: rem(26);
        }
        
        span {
          cursor: pointer;
          img {
            width: rem(24);
            height: rem(24);
          }
        }
        span:nth-child(1){
          // margin-right: rem(25);
        }
        span:nth-last-child(2){
          // margin-right: rem(37);
        }
      }
    }
  }
}

.mat-menu-item{
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 21px !important;
  display: flex !important;
  align-items: center !important;
  color: #ADB5BD !important;
}

