@import "../variables";
@import "../main";

.sb-material-dark .sb-wrapper {
  @each $button,
  $color in $sb-buttons {
    &.sb-#{$button} {
      background-color: $color;
    }
  }
  background-color: $sb-primary;
  background-color: var(--button-color) !important;
  font-family: 'Roboto', sans-serif;
  color: $sb-accent;
  border-radius: 2px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
  &:active {
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
  }

  &.sb-show-icon.sb-show-text {
    padding-left: 0.3em;
    .sb-text {
      padding-right: 1em;
      padding-left: 0;
    }
  }
}
