
.ng-select {
  display: flex !important;
}
.ng-select.custom {
  border:0px;
  min-height: 0px;
  border-radius: 0;
}
.ng-select-container  {
  height: rem(45) !important;
  font-family: Open Sans !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  letter-spacing: 0.2px !important;
  color: #868E96 !important;
}

.ng-dropdown-panel-items{
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  color: #495057;
  scrollbar-width: thin;
  scrollbar-color: #008837 transparent;
}

::ng-deep .ng-dropdown-panel .scroll-host::-webkit-scrollbar {
  width: 7px !important;
  background-color: transparent;
}

::ng-deep .ng-dropdown-panel .scroll-host::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  background-color: transparent !important;
}

::ng-deep .ng-dropdown-panel .scroll-host:hover::-webkit-scrollbar-thumb {
  background: #008837 !important;
}

::ng-deep .ng-dropdown-panel .scroll-host::-webkit-scrollbar-track {
  border-radius: 6px !important;
  background-color: transparent !important;
}
