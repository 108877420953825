// ==========================================================================
// Colors
// ==========================================================================

// somaicovid
// ==========================================================================

$somaicovid-colors: ('somaicovid-primary' : #157bad,
  'somaicovid-secondary': #D6D5D3,
  'somaicovid-success' : #08BFA1,
  'somaicovid-warning' : #f5a600,
  'somaicovid-orange' : #FE7D53,
  'somaicovid-gren' : #298E43,
  'somaicovid-black' : #000000,
  'somaicovid-error' : #ff0000,
  'somaicovid-gray-1' : #4A4A4A,
  'somaicovid-gray-2' : #4f4f4f,
  'somaicovid-gray-3' : #828282,
  'somaicovid-gray-4' : #7B7B7B,
  'somaicovid-gray-5' : #CACACA,
  'somaicovid-gray-6' : #e3e2e2,
  'somaicovid-gray-7' : #E9ECEF,
  'somaicovid-white' : #FFFFFF,
);


// dpw
// ==========================================================================

$dpw-colors: ('dpw-primary' : #3da6c8,
  'dpw-secondary' : darken(#3da6c8, 12%),
);


// Social Media
// ==========================================================================

$social-media-colors: ('facebook' : #3b5998,
  'twitter' : #00aced,
  'linkedin' : #0077b5,
  'youtube' : #cd201f,
  'instagram' : #e4405f,
  'github' : #333,
);



// ==========================================================================
// Measures
// ==========================================================================

$global-max-width-raw: 780;
$global-max-width : rem($global-max-width-raw);

$global-font-size : rem(18);
$global-line-height: 1.5;
$global-radius : rem(6);
$global-module-size: rem(30);

// ==========================================================================
// Transitions
// ==========================================================================

$transition-duration : 305ms;
$transition-duration-complex : 385ms;
$transition-duration-entrance: 235ms;
$transition-duration-exit : 205ms;
$transition-duration-fast : 150ms;

$transition-function-ease-in-expo : cubic-bezier(.95, .05, .795, .035);
$transition-function-ease-out-expo : cubic-bezier(.19, 1, .22, 1);
$transition-function-ease-in-back : cubic-bezier(.6, -.28, .735, .045);
$transition-function-ease-out-back : cubic-bezier(.175, .885, .32, 1.275);
$transition-function-ease-in-out-circ : cubic-bezier(.785, .135, .15, .86);
$transition-function-ease-in-out-quint: cubic-bezier(.86, 0, .07, 1);
$transition-function-elastic : cubic-bezier(.175, .885, .41, 1.46);



// ==========================================================================
// Others
// ==========================================================================

$z-indexes: ('above' : 500,
  'modal' : 400,
  'overlay' : 300,
  'dropdown': 200,
  'default' : 1,
  'below' : -1,
);
