
.leaflet-bar {
  background: #FFFFFF !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04) !important;
  border-radius: rem(8) !important;
  border: 0px solid rgba(0,0,0,0.2) !important;
  transform: rotate(270deg);
  margin-left: rem(60) !important;


  a.polyline-measure-unicode-icon, a.polyline-measure-unicode-icon:hover {
    width: 2.5rem !important;
    height: 2.5rem !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #4d8a37;
    border-radius: 8px !important;
  }
}

.leaflet-bottom .leaflet-control {
  margin-bottom: -10px !important;
}

.leaflet-bottom{
  margin-left: rem(5) !important;
  margin-bottom: rem(20) !important;
}

@include breakpoint(large-up) {
  .leaflet-bottom{
    // bottom: rem(120) !important;
  }
}

.leaflet-control-zoom-in{
  width: 100% !important ;
  height: rem(40) !important;
  border-bottom-left-radius: 8px !important;
  border-top-left-radius: 8px !important;
  color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./../../assets/img/zoom-in.svg');
  background-size: 27px !important;
  background-position-y: 7px !important;
  background-position-x: 8px !important;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.leaflet-control-zoom-out{
  width: 100% !important ;
  height: rem(40) !important;
  border-bottom-right-radius: 8px !important;
  border-top-right-radius: 8px !important;
  color: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./../../assets/img/zoom-out.svg');
  background-size: rem(30) !important;
  background-position-y: rem(7) !important;
  background-position-x: rem(8) !important;
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.leaflet-control-attribution{
  display: none !important;
}
a.leaflet-popup-close-button {
  display: none !important;
}


.leaflet-draw-section {
  position: absolute !important;
  margin-left: rem(45);
  margin-top: rem(0);
}

a.leaflet-draw-draw-marker{
  width: 2.5rem !important;
  height: 2.5rem !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d8a37;
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;  
}

.leaflet-draw-toolbar .leaflet-draw-draw-marker{
  background-position: center !important;
}

a.leaflet-draw-draw-marker {
  color:#fff !important;
  font-size:0.0001em !important;
  text-indent:-9999px !important;
  background-image:url('../../assets/img/question_place.svg') !important;
  background-size: 24px 24px;
  transform: rotate(90deg) !important;
}

:host ::ng-deep .polyline-measure-controlOnBgColor{
  background-color: #a9c79b;
}
:host ::ng-deep .polyline-measure-controlOnBgColor:hover{
  background-color: #a9c79b;
}

.leaflet-bar a:hover {
  background-color: #a9c79b;
}

// .leaflet-bar a:hover{
//   transition: 0.1s ease-in;
//   background-color: #008837 !important;
//   .leaflet-draw-draw-marker{
//     color: #fff;
//     transition: 0.1s ease-in;
//     filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(112deg) brightness(104%) contrast(101%);
//   }
// }