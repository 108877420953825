$layout-name: "l-content";

.#{$layout-name} {
  grid-area: content;
  background-color: #fafafa;
  color: var(--color-somaicovid-white);
  width: 100%;
  @include breakpoint(large-up) {
    display: grid;
  }
}
